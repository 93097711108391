import Link from 'next/link';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/Button';
import Input from 'components/Input';
import { EmailRegEx } from 'data/globalConstants';
import { validation } from 'lang/en.json';
import LoginLayout from 'layouts/LoginLayout';

import { authenticate, authSelector } from 'store/auth';

import styles from 'styles/modules/Login.module.scss';
import parseJwt from 'utils/parse-jwt';
import SEO from 'next-seo.config';
import { NextSeo } from 'next-seo';
import auth from 'utils/auth';
import { wrapper } from 'store';
import Typo from 'components/Typo';

const Login = () => {
    const dispatch = useDispatch();
    const { isAuthenticated, token, error } = useSelector(authSelector);
    const router = useRouter();

    const { register, handleSubmit, errors } = useForm();
    const [visibleError, setVisibleError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const showErrorMessage = () => {
        console.log(error);
        setVisibleError(true);
        setLoading(false);
    };

    const onSubmit = async (data) => {
        setLoading(true);
        setErrorMessage('');
        dispatch(authenticate(data));

        if (error) {
            setErrorMessage(error);
            return showErrorMessage();
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            const { company, isJournalist } = parseJwt(token);
            if (isJournalist) {
                Sentry.setUser({ journalist: company });
            } else {
                Sentry.setUser({ company: company });
            }
            const { redirect } = router.query;
            if (redirect) {
                router.push(`${redirect}`).then(() => window.scrollTo(0, 0));
            } else {
                router.push(`${company}`).then(() => window.scrollTo(0, 0));
            }
        }

        if (error) {
            showErrorMessage();
        }

        setErrorMessage(error);
    }, [isAuthenticated, error]);

    return (
        <>
            <NextSeo {...SEO} titleTemplate={`Login | %s`} />
            <LoginLayout>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* HEADER */}
                    <header className={styles.header}>
                        {errorMessage && visibleError && (
                            <div className={styles.errorBox}>
                                The email or password is not correct.
                                <br />
                                Did you{' '}
                                <Link href={'/forgot-password'}>
                                    <a>forget your password?</a>
                                </Link>
                            </div>
                        )}
                        {errorMessage === 'Deactivated' && (
                            <div className={styles.errorBox}>
                                Your account has been deactivated, please
                                contact{' '}
                                <a href="mailto:support@newspage.co.uk">
                                    support@newspage.co.uk
                                </a>
                            </div>
                        )}
                        <Typo Tag={'h1'} variant={'h1'} className={'mb-l'}>
                            Welcome back!
                        </Typo>
                    </header>

                    {/* INPUTS */}
                    <div className={styles.inputWrapper}>
                        <Input
                            name={'email'}
                            type={'email'}
                            label={'E-mail'}
                            color={'blue'}
                            error={errors['email']}
                            register={register({
                                required: validation.required,
                                pattern: {
                                    value: EmailRegEx,
                                    message: validation.email,
                                },
                            })}
                        />

                        <Input
                            type={'password'}
                            label={'Password'}
                            name={'password'}
                            color={'blue'}
                            error={errors['password']}
                            register={register({
                                required: validation.required,
                            })}
                        />
                    </div>

                    {/* LINKS */}
                    <div className={['mb-l', styles.linksWrapper].join(' ')}>
                        <div className={styles.col}>
                            <label className={styles.remember_me}>
                                <input
                                    ref={register()}
                                    type="checkbox"
                                    name="remember_me"
                                />
                                <span>Remember me</span>
                            </label>
                        </div>
                    </div>

                    {/* LINKS */}
                    <div className={['mb-l', styles.linksWrapper].join(' ')}>
                        <div className={styles.col}>
                            <Link href={'/forgot-password'}>
                                <a>Can&apos;t log in?</a>
                            </Link>
                        </div>
                        <div className={[styles.col, 'textRight'].join(' ')}>
                            Not registered yet?{' '}
                            <Link href={'/register'}>
                                <a>Join now!</a>
                            </Link>
                        </div>
                    </div>

                    {/* BUTTON */}
                    <Button
                        title={'Log in'}
                        theme={'primary'}
                        type={'submit'}
                        loading={loading}
                    />
                </form>
            </LoginLayout>
        </>
    );
};

export const getServerSideProps = wrapper.getServerSideProps(
    (store) =>
        async ({ req, res }) => {
            await auth(store, req, res);
            const state = store.getState().auth;
            if (state.isAuthenticated) {
                return {
                    redirect: {
                        destination: `/${state.company}`,
                        permanent: false,
                    },
                };
            }
            return {
                props: {},
            };
        }
);

export default wrapper.withRedux(Login);
