import { useEffect, useState } from 'react';
import styles from 'styles/modules/Input.module.scss';

import Eye from './Svg/Eye';

const Input = ({
    value,
    type,
    onFocus,
    onBlur,
    onChange,
    onKeyUp,
    placeholder,
    label,
    name,
    disabled,
    color,
    error,
    register,
    classes = [],
    labelAbove,
}) => {
    const [inputType, setInputType] = useState(type || 'text');

    const [active, setActive] = useState(false);

    useEffect(() => {
        const inputValue = document.getElementById(name)?.value;
        if (
            (value && value.trim() !== '') ||
            (inputValue && inputValue.trim() !== '')
        )
            setActive(true);
    }, []);

    return (
        <div
            className={[
                styles.inputWrapper,
                styles[`inputWrapper${color}`],
                error ? styles.inputWrapperError : null,
                labelAbove ? styles.hasLabelAbove : '',
            ].join(' ')}
        >
            {labelAbove && label && (
                <label className={styles.labelAbove} htmlFor={name}>
                    {label}
                </label>
            )}
            <input
                id={name}
                name={name}
                type={inputType}
                className={[
                    styles.input,
                    active ? styles.inputActive : null,
                    ...classes,
                    error ? styles.error : '',
                    labelAbove ? styles.hasLabelAbove : '',
                ].join(' ')}
                onFocus={(event) => {
                    setActive(true);
                    onFocus ? onFocus(event) : null;
                }}
                onBlur={(event) => {
                    event.target.value ? null : setActive(false);
                    onBlur ? onBlur(event) : null;
                }}
                onChange={(event) => {
                    setActive(true);
                    onChange ? onChange(event) : null;
                }}
                onKeyUp={
                    onKeyUp
                        ? ({ target: { value } }) => onKeyUp(name, value.trim())
                        : null
                }
                ref={register}
                placeholder={placeholder}
                defaultValue={value}
                disabled={disabled}
            />
            {!labelAbove && label && <label htmlFor={name}>{label}</label>}
            {type === 'password' && (
                <div
                    className={styles.password}
                    onClick={() =>
                        inputType === 'password'
                            ? setInputType('text')
                            : setInputType('password')
                    }
                >
                    <Eye />
                </div>
            )}
            {error?.message && <p className={styles.error}>{error.message}</p>}
        </div>
    );
};

export default Input;
