import styles from 'styles/modules/Typo.module.scss';

const Typo = (props) => {
    let { variant, Tag, color, className, children, font } = props;

    if (!Tag) {
        switch (variant) {
            case 'h1':
            case 'h2':
            case 'h3':
            case 'h4':
            case 'h5':
            case 'h6':
            case 'blockquote':
                Tag = variant;
                break;
            default:
                Tag = 'p';
        }
    }

    if (!variant) variant = 'body';

    const classes = [
        styles.typo,
        variant ? styles[`typo--${variant}`] : null,
        color ? styles[`typo--${color}`] : null,
        font ? styles[`typo--${font}`] : null,
        className ? className : null,
    ]
        .filter(Boolean)
        .join(' ');

    const modifiedProps = {
        ...props,
        className: classes,
    };

    // Remove props so they don't appear in DOM
    delete modifiedProps.variant;
    delete modifiedProps.Tag;
    delete modifiedProps.font;

    return <Tag {...modifiedProps}>{children}</Tag>;
};

export default Typo;
