export default function Eye(props) {
    const fill = props.fill ?? '#212121';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 1418 916"
        >
            <path
                fill={fill}
                d="M708.531 676.277c120.483 0 218.154-97.671 218.154-218.154 0-120.483-97.671-218.154-218.154-218.154-120.483 0-218.154 97.671-218.154 218.154 0 120.483 97.671 218.154 218.154 218.154z"
            />
            <path
                fill={fill}
                d="M1395.29 397.695C1227.6 195.057 973.767 0 708.516 0 443.214 0 189.318 195.193 21.7404 397.695c-28.9872 35.013-28.9872 85.843 0 120.857 42.131 50.911 130.4556 149.19 248.4226 235.061 297.096 216.277 578.956 216.755 876.707 0 117.97-85.871 206.29-184.15 248.42-235.061 28.9-34.946 29.05-85.729 0-120.857zM708.516 152.708c168.415 0 305.414 137 305.414 305.415 0 168.415-136.999 305.416-305.414 305.416S403.101 626.538 403.101 458.123s137-305.415 305.415-305.415z"
            />
        </svg>
    );
}
