import styles from 'styles/modules/Login.module.scss';

export default function LoginLayout(props) {
    return (
        <div className={styles.pageLogin}>
            <div className={['container', styles.container].join(' ')}>
                <div className={styles.body}>
                    <div
                        className={styles.bodyWrapper}
                        style={{
                            width: props.forceWidth ? '100%' : undefined,
                            maxWidth: props.forceWidth ? 'none' : undefined,
                        }}
                    >
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
}
